function Work() {
  return (
    <div className="work-section">
      <h2 className="sub-title">Work experience</h2>
      <ul className="section-list">
        <li className="section-list-item">
          Full Stack Develpoment Instructor at Foundation Forward.{" "}
          <mark> 2023 - Present </mark>
        </li>
        <li className="section-list-item">
          Research and Teaching Assistant at the University of British Columbia.{" "}
          <mark> 2021 - 2023 </mark>
        </li>
        <li className="section-list-item">
          Web Developer at Eleven Eleven Marketing Agency.{" "}
          <mark> 2017 - 2018 </mark>
        </li>
      </ul>
    </div>
  );
}

export default Work;
