import { Link } from "react-router-dom";

function Theses() {
  return (
    <div className="projects-section">
      <h2 className="sub-title">Theses</h2>
      <div class="featured-items">
        <Link to={`/thesis/UBC`} className="project-link">
          <div class="project-item">
            <div class="project-info">
              <h3 class="project-name">
                Exploring the influence of prototyping fidelity on feedback for
                an app with multi-sided interaction model
              </h3>
              <p class="project-year">
                The University of British Columbia, 2023
              </p>
              <p class="project-type">Master's Thesis</p>
            </div>
          </div>
        </Link>
        <Link to={`/thesis/KIC`} className="project-link">
          <div class="project-item">
            <div class="project-info">
              <h3 class="project-name">
                {" "}
                E-Mentoring Social Service for Youth via Mobile Application: a
                Case Study{" "}
              </h3>
              <p class="project-year">Kobe Institute of Computing , 2020</p>
              <p class="project-type">Master's Thesis</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Theses;
