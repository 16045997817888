import { useLayoutEffect } from "react";
import Footer from "./Footer";
import "../style/Theses.css";

import img1 from "../assets/UBC1.png";
import img2 from "../assets/UBC2.jpg";
import img3 from "../assets/UBC3.png";
import img4 from "../assets/UBC4.png";
import img5 from "../assets/UBC5.png";
import back from "../assets/back.png";

function ThesisUBC() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <div className="fixed-button">
        <a href="/">
          {" "}
          {/* Replace "/" with the URL of your home page */}
          <button>
            <img src={back} alt="" />
          </button>
        </a>
      </div>
      <div className="bio">
        <br />
        <br />
        MSc Thesis in Computer Science, The University of British Columbia,
        2023. <br />
        <strong>Title: </strong> Exploring the influence of prototyping fidelity
        on feedback for an app with multi-sided interaction model <br />
        <a
          href="https://open.library.ubc.ca/soa/cIRcle/collections/ubctheses/24/items/1.0437959"
          target="_blank"
          rel="noopener noreferrer"
        >
          Access Link
        </a>
        <h2 className="sub-title">Overview</h2>A proof-of-concept multi-sided
        booking mobile app for renting adaptive equipment. I developed a fully
        functional mobile app and an equivalent paper prototype to conduct a
        user study to explore the influence of prototyping fidelity on feedback
        received from participants.
        <img src={img1} alt="" className="thesis-img UBCImg1" />
        <p className="cpation">
          the home page of the app from the Android simulator
        </p>
        <h2 className="sub-title">Exploration</h2>
        <p>
          The app aims to provide online access for renting adaptive devices to
          the target group. I developed and tested a prototype that handles the
          booking process, which requires the synchronization of the user, a
          volunteer, and the device to be rented within time slots.
        </p>
        <p>
          I developed a fully functional app (high-fidelity) for iOS and Android
          devices and a content management system that can be accessed through
          the Web. Besides, I designed a paper prototype (low-fidelity) that is
          different in medium but identical in functionality to the real app.
        </p>
        <p>
          The research aimed to answer the question of how the type of fidelity
          of a mobile app prototype would affect the feedback received from
          testers.
        </p>
        <img src={img2} alt="" className="thesis-img UBCImg2" />
        <p className="cpation">
          paper prototype (low-fidelity) vs mobile app prototype (high-fidelity)
        </p>
        <h2 className="sub-title">Solution</h2>
        <p>
          To explore the influence of prototyping fidelity on feedback, I
          formulated a formal user study consisting of usability testing,
          surveys, and interviews. The study is a 2 x 1 between-group
          experiment, in which the main independent variable is the prototype
          fidelity; each participant tested one fidelity and provided feedback
          for the user and the volunteer interfaces.
        </p>
        <p>
          I used the System Usability Scale (SUS) survey to collect quantitative
          data, and semi-structured interviews to collect qualitative data. 12
          participants were recruited and randomly assigned to test a fidelity
          (6 to each level of fidelity).
        </p>
        <img src={img3} alt="" className="thesis-img UBCImg1" />
        <img src={img4} alt="" className="thesis-img UBCImg1" />
        <p className="cpation">
          calendar page from the user side (Top), calendar page from the
          volunteer side (Bottom)
        </p>
        <h2 className="sub-title">Outcome</h2>
        <p>
          I analyzed the quantitative data to determine if there would be a
          noticeable difference in responses between the two groups of testers.
          I found that the type of fidelity did not affect rating usability.
        </p>
        <p>
          I used the conventional content analysis method to analyze the
          qualitative data collected from interviews, and distinguished
          different categories that emerged from the responses of participants.
          The analysis showed commonalities and differences in the feedback
          received from the two groups.
        </p>
        <img src={img5} alt="" className="thesis-img UBCImg3" />
        <p className="cpation">
          the average scores of the SUS survey questions between the two groups
          of testers
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default ThesisUBC;
