import { useLayoutEffect } from "react";
import Footer from "./Footer";
import "../style/Theses.css";

import KIC1 from "../assets/KIC1.png";
import KIC2 from "../assets/KIC2.png";
import KIC3 from "../assets/KIC3.png";
import KIC4 from "../assets/KIC4.png";
import KIC5 from "../assets/KIC5.png";

import back from "../assets/back.png";

function ThesisKIC() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <div className="fixed-button">
        <a href="/">
          {" "}
          {/* Replace "/" with the URL of your home page */}
          <button>
            <img src={back} alt="" />
          </button>
        </a>
      </div>
      <div className="bio">
        <br />
        <br />
        MSc Thesis in Information Systems, Kobe Institute of Computing, 2020.
        <br />
        <strong>Title: </strong> E-Mentoring Social Service for Youth via Mobile
        Application: a Case Study <br />
        <h2 className="sub-title">Overview</h2>A mobile app for career and
        education mentoring purposes. I used a problem-solving framework,
        questionnaires, prototyping, and usability testing to conduct the
        research, starting by idetifying the core problem, towards suggesting
        and implementing the solution.
        <img src={KIC1} alt="" className="thesis-img KICImg1" />
        <p className="cpation">
          screenshot from the interface of the app's home page (Android phone
          screenshot)
        </p>
        <h2 className="sub-title">Exploration</h2>
        <p>
          The hypothesis is that: many students are often at unease about which
          educational discipline to acquire and which career path to follow. To
          verify the hypothesis, I carried out an investigational questionnaire
          to sense the prevalence of the problem and the need for the proposed
          solution.
        </p>
        <p>
          Based on the literature review and the results of the questionnaire, I
          derived design directions and started developing a prototype that
          emulates the proposed application.
        </p>
        <img src={KIC2} alt="" className="thesis-img KICImg2" />
        <p className="cpation">
          sample of results from the investigational questionnaire of more than
          100 participants
        </p>
        <h2 className="sub-title">Solution</h2>
        <p>
          Following a procedure, I formulated the solution: e-mentoring service
          consisting of video, audio, and text scheduled conversations via
          mobile application with mentors. I used Flutter and Firebase to
          develop a fully functional app for Android devices and used webRTC to
          enable video and voice communication in the app.
        </p>
        <p>
          I recruited 10 participants to conduct online usability testing for
          the developed prototype, and participants were asked to fill out a
          survey afterwards.
        </p>
        <img src={KIC3} alt="" className="thesis-img KICImg2" />
        <p className="cpation">
          sample of results from the final questionnaire
        </p>
        <h2 className="sub-title">Outcome</h2>
        <p>
          By observing participants during the usability testing and by
          analyzing the questionnaire results, I derived research-based design
          recommendations for the e-mentoring app.
        </p>
        <div className="KICImg45">
          <img src={KIC4} alt="" className="thesis-img KICImg4" />
          <img src={KIC5} alt="" className="thesis-img KICImg5" />
          <p className="cpation">
            options for starting a session, and video call implementation
            (Android phone screenshots)
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ThesisKIC;
