import LinkedInLogo from "../assets/linkedin-icon.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-item">
        <a
          href="https://www.linkedin.com/in/yaman-sanobar/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedInLogo} alt="Twitter Logo" />
        </a>
      </div>
      <div className="footer-item">
        <p>
          <a href="mailto:yaman.sanobar@gmail.com">Contact</a>
        </p>
      </div>
      <div className="footer-item">
        <p> &copy;2024 Yaman Sanobar</p>
      </div>
    </footer>
  );
}

export default Footer;
